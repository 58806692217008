/*!
* styles
*/

/* -----------------------------------------------------------------------------
 * dependencies
 * -------------------------------------------------------------------------- */

@use "~normalize.css/normalize.css";
@use "~@walrus/theme/src/theme.css";
@import "~@walrus/theme";

/* -----------------------------------------------------------------------------
 * base
 * -------------------------------------------------------------------------- */

body {
  margin: 0;
  background: theme($colors, white);
  color: theme($colors, shade07);
  @include themeFont(text14Medium);
  word-wrap: break-word;
}

/* -----------------------------------------------------------------------------
 * typography
 * -------------------------------------------------------------------------- */

h1,
h2,
h3,
h4 {
  color: theme($colors, purple);
}

h1 {
  @include themeFont(display32Bold);
}

h2 {
  @include themeFont(display24Bold);
}

h3 {
  @include themeFont(display20Bold);
}

h4 {
  @include themeFont(text16Bold);
}

a {
  color: theme($colors, violet);
  text-decoration: none;
}

.label {
  color: theme($colors, purple);
  text-transform: uppercase;
  @include themeFont(text12Bold);
}

/* -----------------------------------------------------------------------------
 * elements
 * -------------------------------------------------------------------------- */

hr {
  border: 0;
  height: 0;
  border-bottom: 1px solid theme($colors, shade03);
}

ol,
ul {
  padding-left: 1.5em;
}

ol li,
ul li {
  margin-top: 1em;
  margin-bottom: 1em;
}

ol > li > ol {
  list-style-type: lower-alpha;
}

/* -----------------------------------------------------------------------------
 * helpers
 * -------------------------------------------------------------------------- */

.inline {
  display: inline;
}

/* -----------------------------------------------------------------------------
 * components
 * -------------------------------------------------------------------------- */

.container {
  margin: 0 auto;
  padding: theme($spaces, 20);
}

.content {
  margin: 0 auto;
  max-width: 680px;
}

.page-title {
  text-align: center;
  padding-bottom: theme($spaces, 20);
}

.summary {
  text-align: center;
}

.address {
  text-align: center;
}

.divided-list {
  text-align: center;
  margin: 0;
  padding: 0;
  list-style: none;
  border-top: 1px solid theme($colors, shade03);
}

.divided-list {
  margin: 1em 0 0 0;
}

.divided-list li {
  margin: 0;
  padding: theme($spaces, 08);
  border-bottom: 1px solid theme($colors, shade03);
}

.inline {
  display: inline;
}
