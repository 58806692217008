$breakpoints: (mobile: 0px,tablet: 768px,desktop: 1224px,desktopXL: 1824px);
$colors: (white: #FFFFFF,black: #000000,shade01: #FAFAFD,shade01b: #F4F7FA,shade02: #EFF1F3,shade02b: #EFF3F8,shade03: #E6E9ED,shade03b: #E4EBF3,shade04: #B5BECA,shade05: #8493A6,shade05b: #9498BD,shade06: #6B7D94,shade07: #09274D,shade08: #061B36,shade09: #030C17,blue: #2DB1EA,blueShade: #D1D5FC,paleBlue: #F7F8FD,paleBlue02: #F1F2FE,lightBlue: #80DAF7,darkBlue: #5E83F5,green: #15C2B8,paleGreen: #08CAA7,darkGreen: #15B04A,lightGreen: #E0F9F3,mintGreen: #F4FFFD,everestGreen: #28667B,newPurple: #363F89,newMutedPurple: #6670BC,purple: #3E4088,lightPurple: #C763F7,mutedPurple: #6976AE,violet: #5162F4,paleViolet: #E3E7F8,lightViolet: #F3F4FF,red: #FF4359,paleRed: #EE4F58,yellow: #FFC449,paleYellow: #F2C85B,orange: #FF8719,paleOrange: #FC9F5D,lightOrange: #FFF6E0,darkOrange: #5F3838,coolGray: #888BB6,error: #D10033,paleError: #F9EFEF,pink: #EE727F,lightPink: #FAEDEC,darkPink: #5B2E3B,transparent: transparent,walrusRed050: #F6F5F6,walrusRed200: #E3C2D2,walrusRed800: #85353A,walrusBlue050: #F1F2FE,walrusBlue100: #D1D5FC,walrusBlue200: #363F89,walrusBlue300: #404CAB,walrusBlue500: #5162F4,walrusBlue700: #404CAB,walrusBlue800: #5162F4,walrusGreen500: #15B04A);
$spaces: (12: 12px,16: 16px,20: 20px,24: 24px,32: 32px,40: 40px,48: 48px,64: 64px,98: 98px,00: 0px,02: 2px,04: 4px,08: 8px);
$radii: (none: 0px,xsmall: 2px,small: 4px,medium: 6px,large: 12px,xlarge: 20px,pill: 99px);
$fonts: (text10Medium: (fontFamily: (Inter, sans-serif),fontSize: 10px,fontWeight: 500,lineHeight: 12px),text10Bold: (fontFamily: (Inter, sans-serif),fontSize: 10px,fontWeight: 700,lineHeight: 12px),text12Medium: (fontFamily: (Inter, sans-serif),fontSize: 12px,fontWeight: 500,lineHeight: 16px),text12Semibold: (fontFamily: (Inter, sans-serif),fontSize: 12px,fontWeight: 600,lineHeight: 16px),text12Bold: (fontFamily: (Inter, sans-serif),fontSize: 12px,fontWeight: 700,lineHeight: 16px),text14Medium: (fontFamily: (Inter, sans-serif),fontSize: 14px,fontWeight: 500,lineHeight: 20px),text14Semibold: (fontFamily: (Inter, sans-serif),fontSize: 14px,fontWeight: 600,lineHeight: 20px),text14Bold: (fontFamily: (Inter, sans-serif),fontSize: 14px,fontWeight: 700,lineHeight: 20px),text15Medium: (fontFamily: (Inter, sans-serif),fontSize: 15px,fontWeight: 500,lineHeight: 20px),text16Medium: (fontFamily: (Inter, sans-serif),fontSize: 16px,fontWeight: 500,lineHeight: 24px),text16Semibold: (fontFamily: (Inter, sans-serif),fontSize: 16px,fontWeight: 600,lineHeight: 24px),text16Bold: (fontFamily: (Inter, sans-serif),fontSize: 16px,fontWeight: 700,lineHeight: 24px),display12Bold: (fontFamily: ('GT Walsheim', sans-serif),fontSize: 12px,fontWeight: 700,lineHeight: 16px),display14Bold: (fontFamily: ('GT Walsheim', sans-serif),fontSize: 14px,fontWeight: 700,lineHeight: 18px),display16Bold: (fontFamily: ('GT Walsheim', sans-serif),fontSize: 16px,fontWeight: 700,lineHeight: 24px),display20Bold: (fontFamily: ('GT Walsheim', sans-serif),fontSize: 20px,fontWeight: 700,lineHeight: 24px),text22Medium: (fontFamily: (Inter, sans-serif),fontSize: 22px,fontWeight: 500,lineHeight: 24px),display24Bold: (fontFamily: ('GT Walsheim', sans-serif),fontSize: 24px,fontWeight: 700,lineHeight: 32px),display32Bold: (fontFamily: ('GT Walsheim', sans-serif),fontSize: 32px,fontWeight: 700,lineHeight: 40px));
$shadows: (medium: 0px 2px 6px 0px rgba(62, 64, 136, 0.06),large: 0px 6px 14px 0px rgba(9, 39, 77, 0.16),panel: 0px 0px 3px 0px rgba(36, 38, 95, 0.14));
@function theme($map, $keys...) {
    @each $key in $keys {
        $map: map-get($map, $key);
    }
    @return $map;
}

@mixin themeFont($font) {
  font-family: theme($fonts, $font, fontFamily);
  font-size: theme($fonts, $font, fontSize);
  font-weight: theme($fonts, $font, fontWeight);
  line-height: theme($fonts, $font, lineHeight);
}
